/*globals navigator */

(function () {
  var BOTTOM_EDGE_PADDING = 10;
  var SHOULD_AUTO_TOGGLE_VOLUME = false;

  if (window.VINE_EMBEDS) {
    return;
  } else {
    window.VINE_EMBEDS = true;
  }

  var PRODUCTION = /vine\.co/.test(window.location.host);

  var listenForEvent = (function () {
    if (window.addEventListener) {
      return function (eventName, handler) {
        window.addEventListener(eventName, handler);
      };
    } else {
      return function (eventName, handler) {
        window.attachEvent('on' + eventName, handler);
      };
    }
  }());

  // Thanks Remy
  // https://remysharp.com/2010/07/21/throttling-function-calls
  function debounce (fn, delay) {
    var timer = null;
    return function () {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  }

  function hasClass (element, className) {
    return element.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  }

  function addClass (element, className) {
    if (!hasClass(element, className)) {
      element.className += ' ' + className;
    }
  }

  function removeClass (element, className) {
    if (hasClass(element, className)) {
      var regex = new RegExp('(\\s|^)' + className + '(\\s|$)');
      element.className = element.className.replace(regex, ' ');
    }
  }

  function clamp (x, lo, hi) {
    return Math.max(lo, Math.min(hi, x));
  }

  function getMutableClientRect (node) {
    var rect = node.getBoundingClientRect();
    return {
      top: rect.top,
      bottom: rect.bottom,
      left: rect.left,
      right: rect.right,
      width: rect.width || node.offsetWidth,
      height: rect.height || node.offsetHeight
    };
  }

  function percentageVisibleInBounds (nodeBounds, viewBounds) {
    var cutOffOfTop = clamp(Math.abs(Math.min(0, nodeBounds.top)), 0, nodeBounds.height);
    var cutOffOfBottom = clamp(nodeBounds.bottom - viewBounds.height, 0, nodeBounds.height);
    var cutOffOfLeft = clamp(Math.abs(Math.min(0, nodeBounds.left)), 0, nodeBounds.width);
    var cutOffOfRight = clamp(nodeBounds.right - viewBounds.width, 0, nodeBounds.width);
    var visibleY = nodeBounds.height - cutOffOfTop - cutOffOfBottom;
    var visibleX = nodeBounds.width - cutOffOfLeft - cutOffOfRight;
    var percentVisibleY = clamp(visibleY / nodeBounds.height, 0, 1);
    var percentVisibleX = clamp(visibleX / nodeBounds.width, 0, 1);

    return percentVisibleY * percentVisibleX;
  }

  function percentageVisible (node) {
    var parent = getScrollableParent(node);
    var nodeRect = getMutableClientRect(node);

    var withinViewport = percentageVisibleInBounds(nodeRect, {
      scrollY: window.pageYOffset,
      scrollX: window.pageXOffset,
      width: window.innerWidth,
      height: window.innerHeight
    });

    if (parent === document.body) {
      return withinViewport;
    } else if (withinViewport > 0) {
      return percentageVisible(parent);
    } else {
      return withinViewport;
    }
  }

  function getScrollableParent (node) {
    var parent = node.parentNode;
    return (parent === document.body || window.getComputedStyle(parent).getPropertyValue('overflow') === 'scroll') ? parent : getScrollableParent(parent);
  }

  function getDocumentHeight () {
    return Math.max(
      document.documentElement.clientHeight,
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight
    );
  }

  function isScrolledToBottomOfPage () {
    return window.scrollY + window.innerHeight > getDocumentHeight() - BOTTOM_EDGE_PADDING;
  }

  function manageVinesInViewport (embeds) {
    embeds = embeds || document.querySelectorAll(PRODUCTION ? 'iframe[src*="vine.co"]' : 'iframe');

    var embedGroups = Array.prototype.slice.call(embeds, 0).filter(function (embed) {
      return hasClass(embed, 'pinged');
    }).map(function (embed) {
      return {
        embed: embed,
        visibility: percentageVisible(embed)
      };
    });

    // Sort by visibility
    embedGroups.sort(function (embedA, embedB) {
      if (embedA.visibility > embedB.visibility) {
        return -1;
      } else if (embedA.visibility < embedB.visibility) {
        return 1;
      } else {
        return 0;
      }
    });

    var mostVisible = embedGroups[0];
    if (SHOULD_AUTO_TOGGLE_VOLUME && isScrolledToBottomOfPage()) {
      for (var i = 1; i < embedGroups.length; i++) {
        if (embedGroups[i].visibility === mostVisible.visibility) {
          mostVisible = embedGroups[i];
          break;
        }
      }
    }

    embedGroups.forEach(function (embedGroup) {
      var embed = embedGroup.embed;
      var visibility = embedGroup.visibility;
      if (embed !== mostVisible.embed) {
        removeClass(embed, 'fully-in-view');
      }

      if (visibility > 0.00) {
        if (!hasClass(embed, 'in-view')) {
          addClass(embed, 'in-view');
          embed.contentWindow.postMessage('scrolledInToView', '*');
        }
      } else if (hasClass(embed, 'in-view')) {
        removeClass(embed, 'in-view');
        embed.contentWindow.postMessage('scrolledOutOfView', '*');
      }
    });

    if (SHOULD_AUTO_TOGGLE_VOLUME && !hasClass(mostVisible.embed, 'fully-in-view')) {
      addClass(mostVisible.embed, 'fully-in-view');
      mostVisible.embed.contentWindow.postMessage('fullyInView', '*');
    }
  }

  listenForEvent('scroll', debounce(manageVinesInViewport.bind(null, null), 100));
  listenForEvent('message', function (event) {
    if (PRODUCTION && !/(vine\.co|localhost|(127|0)\.0\.0\.\d)/.test(event.origin)) {
      return;
    }

    var e, embed, i, embeds;
    try {
      e = event.data.split('::');
    } catch (e) { }

    if (!e) {
      return;
    }

    embed = Array.prototype.slice.call(document.querySelectorAll('iframe'), 0).filter(function (frame) {
      return frame.contentWindow === event.source;
    })[0];

    if (!embed) {
      // We don't know who sent you.
      return;
    }

    if (e[0] === 'ping') {
      addClass(embed, 'pinged');
      removeClass(embed, 'in-view');
      manageVinesInViewport();
      embed.setAttribute('frameborder', 0);

      if (!embed.getAttribute('data-no-clamp')) {
        var div = document.createElement('div');
        embed.parentElement.insertBefore(div, embed);

        var currentWidth = embed.offsetWidth;
        var parentWidth = div.offsetWidth;
        var bodyWidth = document.body.offsetWidth;

        if (currentWidth > parentWidth || currentWidth > bodyWidth) {
          var width = Math.min(parentWidth, bodyWidth);
          embed.setAttribute('width', width);
          embed.setAttribute('height', width);

          embed.contentWindow.postMessage('widthChanged', '*');
        }
      }

      embed.contentWindow.postMessage('pong', '*');
    } else if (e[0] === 'height' && e[2]) {
      if (embed.style.removeProperty) {
        embed.style.removeProperty('height');
      } else {
        embed.style.removeAttribute('height');
      }
      embed.height = parseInt(e[2], 10);
    } else if (e[0] === 'userUnmute') {
      SHOULD_AUTO_TOGGLE_VOLUME = true;
      embeds = document.querySelectorAll('iframe.loaded');
      for (i = 0; i < embeds.length; i++) {
        if (embeds[i] !== embed) {
          embeds[i].contentWindow.postMessage('unmutedOtherEmbed', '*');
        }
      }
    } else if (e[0] === 'userMute') {
      SHOULD_AUTO_TOGGLE_VOLUME = false;
    } else if (e[0] === 'unmute') {
      addClass(embed, 'unmuted');
      embeds = document.querySelectorAll('iframe.loaded');
      for (i = 0; i < embeds.length; i++) {
        if (embeds[i] !== embed) {
          removeClass(embeds[i], 'unmuted');
          embeds[i].contentWindow.postMessage('mute', '*');
        }
      }
    } else if (e[0] === 'loaded') {
      addClass(embed, 'loaded');
    }
  });
}());
